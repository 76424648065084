/**
 * 识别操作系统
 * @returns {string}
 */
function detectOS() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "Ios";
    }

    return "unknown";
}

const StringUtils = require('string');

/**
 * 获取订单有效期 ，去掉120秒
 * @param closeTime
 * @returns {string}
 */
function getRealCloseTime(closeTime) {
    const dayjs = require('dayjs');
    const timestamp = dayjs(closeTime).unix()
    const realTimestamp = timestamp - 60
    const realCloseTime = dayjs.unix(realTimestamp).format("YYYY-MM-DD HH:mm:ss")
    return realCloseTime
}

//咸鱼h5
function getXyH5(h5) {
    const scheme = "alipays://platformapi/startapp?appId=20000067&url="
    const mid = "https://mclient.alipay.com/h5/peerpay.htm?"
    const bizNo = StringUtils(h5).between("YES&").s
    return scheme + encodeURIComponent(mid + bizNo)
}

//咸鱼h5去掉微店
function getXyH52(h5) {
    const scheme = "alipays://platformapi/startapp?appId=20000067&url="
    const mid = "https://mclient.alipay.com/h5/peerpay.htm?enableWK=YES&biz_no="
    let bizNo = h5.match(/biz_no=([^&]+)&app_name/)[1]+"&app_name=tb&sc=card&__webview_options__=pd%3dNO";
    return scheme + encodeURIComponent(mid + bizNo)
}
//https://mclient.alipay.com/h5/peerpay.htm?enableWK=YES&biz_no=2024093004200313131061473484_d7138fd91f439ee1f2f54ae31a4b87a4&app_name=tb&sc=card&__webview_options__=pd%3dNO
//https://qr.alipay.com/_d?_b=peerpay&enableWK=YES&biz_no=2024093004200313131061473484_d7138fd91f439ee1f2f54ae31a4b87a4&app_name=mc&sc=qr_code&v=20241007&sign=d647ec&__webview_options__=pd%3dNO&channel=qr_code
//支付宝uid
function getAlipayUidH5(money, uid) {
    const scheme = "alipays://platformapi/startapp?appId=68687093&url="
    let jsonString = `{'a':${money},'s':${money},'u':${uid},'m':909469}`
    const url = "alipays://platformapi/startapp?appId=20000123&actionType=scan&biz_data="
    return scheme + encodeURIComponent(url + jsonString)
}

/**
 * 支付uid
 * @param money
 * @param uid
 * @param orderNo
 * @returns {string}
 */
function getAlipayUidH5V2(money, uid, orderNo) {
    //https://ds.alipay.com/?from=pc&appId=20000116&actionType=toAccount&goBack=NO&amount=9.90&userId=2088042809921179&memo=GC13677852402388
    let scheme = "https://ds.alipay.com/?from=pc&appId=20000116&actionType=toAccount&goBack=NO&amount="
    scheme += money.toFixed(2)
    scheme += "&userId="
    scheme += uid
    scheme += "&memo="
    scheme += orderNo
    return scheme
}
//备用
//https://ds.alipay.com/?from=pc&appId=20000116&actionType=toAccount&goBack=NO&amount=&userId=2088842835391272&memo=

/**
 * 闲鱼支付 启动淘宝付款
 * @param goodsId
 * @returns {string}
 */
function getXianyuZf(goodsId) {
    let scheme = "tbopen://m.taobao.com/tbopen/index.html?h5Url="
    return scheme + encodeURIComponent("https://h5.m.goofish.com/item?id=" + goodsId)
}

/**
 * 淘宝直付 扫码
 * @param goodsId
 * @returns {string}
 */
function getTaobaoZf(goodsId) {
    return "https://main.m.taobao.com/order/index.html?skuId=0&itemId=" + goodsId
}

function getTaobaoZfv2(goodsId) {
    return "https://item.taobao.com/item.htm?id=" + goodsId + "&ft=t"
}

function getTaobaoGame(goodsId) {
    return "taobao://m.tb.cn/a.ZRs8?scm=20140619.pc_detail.itemId.0&id=" + goodsId
}

/**
 * 支付宝小荷包
 * @param pathContent
 * @returns {string}
 */
function getAlipayXhb(pathContent) {
    let scheme = "alipays://platformapi/startapp?appId=10000007&&qrcode=";
    return scheme + encodeURIComponent(pathContent)
}

/**
 * 抖音 ios h5
 * @param payParams
 * @returns {string}
 */
function getTkIos(payParams) {
    let tempCom = 'com.ss.android.ugc.aweme'
    let jsonObj = {
        'requestType': "SafePay",
        'fromAppUrlScheme': "com.ss.android.ugc.aweme"
    }
    jsonObj.dataString = payParams + '&bizcontext={"appkey":"2014052600006128","ty":"ios_lite","sv":"h.a.3.6.5","an":"' + tempCom + '","av":"1.0","sdk_start_time":' + Date.now() + '}'
    return 'alipaymatrixbwf0cml3://alipayclient/?' + encodeURIComponent(JSON.stringify(jsonObj))
}

/**
 * 抖音 安卓h5
 * @param payParams
 * @returns {string}
 */
function getTkAndroid(payParams) {
    console.log(payParams)
    return "alipays://platformapi/startApp?appId=20000125&orderSuffix=" + encodeURIComponent(payParams)
}

function getTkAndroidV2(payParams) {
    let tempCom = 'com.ss.android.ugc.aweme';

    let result = [];
    for (let i = 0; i < length; i++) {
        let ranNum = Math.ceil(Math.random() * 25);
        result.push(String.fromCharCode(65 + ranNum));
    }
    let session = result.join('').toLowerCase();

    let tempSD = payParams + '&bizcontext={"appkey":"2014052600006128","ty":"and_lite","sv":"h.a.3.6.5","an":"' + tempCom + '","av":"1.0","sdk_start_time":' + Date.now() + '}';
    let dt = {
        "sourcePid": Math.floor(Math.random() * (99999 - 1000 + 1)) + 1000,
        "pkgName": tempCom,
        "session": session,
        "external_info": tempSD
    };
    let alipaysUrl = 'alipays://platformapi/startapp?appId=20000125&mqpSchemePay=' + btoa(JSON.stringify(dt)) + '#Intent;scheme=alipays;package=com.eg.android.AlipayGphone;end';
    return alipaysUrl;
}

function getTkAndroidV3(payParams) {
    let tempCom = 'com.ss.android.ugc.aweme';
    let tempSD = payParams + '&bizcontext={"appkey":"2014052600006128","ty":"ios_lite","sv":"h.a.3.6.8","an":"' + tempCom + '","av":"11024942","sdk_start_time":' + Date.now() + '}';
    let dt = {
        "external_info": tempSD,
        "pkgName": tempCom
    };
    let alipaysUrl = 'alipays://platformapi/startapp?appId=20000125&mqpSchemePay=' + btoa(JSON.stringify(dt));
    alipaysUrl = "alipays://platformapi/startapp?appId=20000067&url=" + encodeURIComponent(alipaysUrl);
    return alipaysUrl;
}

/**
 * 支付宝ls
 */
function getAlipayLs(url) {
    let scheme = "alipays://platformapi/startapp?appId=10000007&&qrcode="
    return scheme + encodeURIComponent(url)
}

/**
 * 支付宝当面付
 * @param url
 * @returns {string}
 */
function getAlipayDmf(url) {
    let scheme = "alipays://platformapi/startapp?appId=10000007&&qrcode="
    return scheme + encodeURIComponent(url)
}

function getWanmei(url) {
    let scheme = "alipays://platformapi/startapp?appId=10000007&&qrcode="
    return scheme + encodeURIComponent(url)
}

function getTl(account, money) {
    let amount = money * 20
    return "https://chong.changyou.com/tl/confirmCardOrders.do?cardOrders.gameType=57&cardOrders.chnl=231&cardOrders.gameName=&gameType=57&chnl=231&cardOrders.cardCount=" + amount + "&chnlType=aliwappay&cardOrders.cardPwd=0&currentDiscount.discountRule=&userFrom=ingame&orderCountInfo=&cardOrders.cn=" + encodeURIComponent(account) + "&cardOrders.repeatcn=" + encodeURIComponent(account) + "&orderCount=1&costTime=28500"
}

function getZhouzhuan(url) {
    let scheme = "alipayqr://platformapi/startapp?saId=10000007&clientVersion=3.7.0.0718&qrcode=";
    return scheme + encodeURIComponent(url)
}

function getLpk(url) {
    let scheme = "alipays://platformapi/startapp?appId=20000067&url="
    return scheme + encodeURIComponent(url)
}

function getHb(url) {
    return "alipays://platformapi/startapp?appId=20000067&url=" + encodeURIComponent("https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=2021004168696180&scope=auth_base&redirect_uri=" + encodeURIComponent(url))
}

function getAlipayQrcode(qrcode){
    return "https://render.alipay.com/p/s/i?scheme="+encodeURIComponent("alipays://platformapi/startapp?saId=10000007&qrcode="+encodeURIComponent(qrcode+"?_s=web-other"))
}

function getHbPayUrl(payUrl) {
    let newUrl = payUrl.replace("https://openapi.alipay.com/gateway.do?", "")
    let scheme = "alipays://platformapi/startApp?appId=20000125&orderSuffix="
    return scheme + encodeURIComponent(newUrl) + "#Intent;scheme=alipays;package=com.eg.android.AlipayGphone;end"
}
function getAlipayUidv3(url){
    return "alipays://platformapi/startapp?appId=20000218&url="+encodeURIComponent(url)
}
function getAlipayUidv4(url){
    return "alipays://platformapi/startapp?appId=2021003124679502&closeAllActivityAnimation=true&startMultApp=YES&transAnimate=true&url="+encodeURIComponent(url)
}
function getJddfm(url){
    return "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx32e4d3446c02b4b5&redirect_uri="+encodeURIComponent(url)+"&response_type=code&scope=snsapi_base&state=123&ad_od=share&utm_source=androidapp&utm_medium=appshare&utm_campaign=t_335139774&utm_term=Wxfriends_shareid8bdcdfcccf1e7b63173202309837790788_none_none&connect_redirect=1#wechat_redirect"
}
function getTbDaifu(qrcode){
    let scheme = "alipays://platformapi/startapp?appId=10000007&&qrcode=";
    // 创建 URL 对象
    const urlObj = new URL(qrcode);
    // 获取 orderIds 参数
    const orderIds = urlObj.searchParams.get("orderIds");
    let tbUrl = "https://market.m.taobao.com/app/tp/helppay/pay.html?bizorderids="+orderIds+"&bizcode=ali.china.etao.applet&source=weixin";
    return scheme + encodeURIComponent(tbUrl)
}

/**
 * 淘宝直付，淘宝扫码可以直接到下单页
 * @param goodsId
 * @returns {string}
 */
function getTaobaoZhifu(goodsId){
    const data = {
        ft: "t",
        id: goodsId
    };
    const jsonString = JSON.stringify(data);
    return "https://main.m.taobao.com/order/index.html?buildOrderVersion=3.0&skuId=undefined&exParams=" + encodeURIComponent(jsonString) + "&serviceId=&quantity=1&itemId=" + goodsId + "&buyNow=true&spm=a2141.7c.order.more&clickTime=" + Date.now() + "&bbid=&spmPre="
}


export default {
    detectOS,
    getRealCloseTime,
    getXyH5,
    getXyH52,
    getAlipayUidH5,
    getAlipayUidH5V2,
    getXianyuZf,
    getAlipayXhb,
    getTaobaoZf,
    getTaobaoZfv2,
    getTaobaoGame,
    getTkIos,
    getTkAndroid,
    getTkAndroidV2,
    getTkAndroidV3,
    getAlipayLs,
    getAlipayDmf,
    getWanmei,
    getTl,
    getZhouzhuan,
    getLpk,
    getHb,
    getHbPayUrl,
    getAlipayQrcode,
    getAlipayUidv3,
    getAlipayUidv4,
    getJddfm,
    getTbDaifu,
    getTaobaoZhifu
}
