<template>
    <el-container>
      <el-main>
        <el-row>
          <Qrcode :content="h5Url" v-show="qrShow"></Qrcode>
          <el-result icon="error" title="订单已超时，请勿超时付款" v-show="timeOut"></el-result>
          <el-result icon="success" title="您已成功付款" v-show="paySuccessFlag"></el-result>
        </el-row>
        <el-row>
          <img :src="path" style="width:200px;height: auto" alt="">
        </el-row>
        <el-row>
          <OrderInfoWithCopyOrderNo :money="money" :order-no="orderNo"></OrderInfoWithCopyOrderNo>
        </el-row>
        <el-row>
          <CountDown :expirationDateTime="closeTime" :onCountdownEnd="handleCountdownEnd" v-if="countDownFlag"/>
        </el-row>
        <el-row>
          <PayButton button-name="我已截屏，点我打开抖音扫一扫" :h5Url="h5Url" v-show="payButtonShow"></PayButton>
        </el-row>
        <el-row>
          <div style="text-align: left;font-weight: bold">
            <div>充值教程(必看！)：</div>
            <div>1.点复制订单号，再手机截图，然后打开抖音【扫抖音码】 添加好友</div>
            <div>2.相互关注后，发送【订单号】</div>
            <div>3.核对金额后，发起【转账】</div>
            <div>4.等待客服【接收转账】，即可上分</div>
            <div>5.请注意核对抖音号，主动关注的一律是骗子，我们只会回关！让你转其他抖音号付款的都是骗子 </div>
          </div>
        </el-row>
      </el-main>
    </el-container>
</template>
<script>
import Request from "@/request";
import AlipayLogo from "@/components/AlipayLogo.vue";
import PayTips from "@/components/PayTips.vue";
import CountDown from "@/components/CountDown.vue"
import Qrcode from "@/components/Qrcode.vue";
import AlipayButton from "@/components/AlipayButton.vue";
import startCheckOrderTimer from "@/service/checkOrder";
import OrderInfo from "@/components/OrderInfo.vue";
import PayButton from "@/components/PayButton.vue";
import OrderInfoWithCopyOrderNo from "@/components/OrderInfoWithCopyOrderNo.vue";
export default {
  name: 'DouyinShoudong',
  components: {
    OrderInfoWithCopyOrderNo,
    PayButton,
    OrderInfo,
    PayTips,
    AlipayLogo,
    CountDown,
    Qrcode,
    AlipayButton
  },
  data() {
    return {
      qrShow: false,
      payButtonShow: false,
      orderNo: this.$route.query.no,
      h5Url: '',
      money: 0,
      closeTime: '',
      timeOut:false,
      countDownFlag: true,
      paySuccessFlag:false,
      dialogFlag: false,
      dialogShowClose: false,
      path:'',
    }
  },
  methods: {
    getData() {
      Request.get("/show/qrc?no=" + this.orderNo).then(res => {
        if (res.code !== 0) {
          this.$message({message: res.msg, type: 'error',})
          return
        }
        this.payButtonShow = true
        const data = res.data
        this.closeTime = this.$utils.getRealCloseTime(data.closeTime)
        //跳转
        this.h5Url = "snssdk1128://"
        this.path = data.path
        this.money = data.money
      });
    },
    //处理倒计时
    handleCountdownEnd(){
      this.qrShow = false
      this.payButtonShow = false
      this.timeOut = true
      //销毁检查订单任务
      this.stopCheckOrderTimer()
    },
    //处理检查订单状态
    handleCheckOrder(data){
      if(data.code !== 0){
        return
      }
      if(data.data.state === "TIMEOUT"){
        //销毁检查订单任务
        this.stopCheckOrderTimer()
      }
      if(data.data.state === "OOK"){
        //显示成功付款提示
        this.paySuccessFlag = true
        //销毁倒计时组件
        this.countDownFlag = false
        //销毁检查订单任务
        this.stopCheckOrderTimer()
        //隐藏付款码
        this.qrShow = false
        this.payButtonShow = false
      }
    },
  },
  mounted() {
    this.getData()
    this.stopCheckOrderTimer = startCheckOrderTimer(this.orderNo,this.handleCheckOrder)
  },
}
</script>
<style>
.el-row{
  margin-bottom: 5px !important;
}
.el-main{
  padding: 5px;
}
</style>
