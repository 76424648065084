<template>
  <div>
    <div></div>
    <el-container>
      <el-main>
        <CountDown :expirationDateTime="closeTime"
                   :onCountdownEnd="handleCountdownEnd"
                   v-show="countDownFlag"/>
        <el-row style="width: 100%;height: 50px;"
                v-loading="loadingFlag"
                element-loading-text="加载中"
                element-loading-spinner="el-icon-loading"
        >
        </el-row>
        <el-row>
          <div class="amountTitle"></div>
        </el-row>
        <el-row>
          <el-form ref="form" :rules="rules" :model="form" label-width="0;">
            <el-form-item label="回调地址" prop="mchNotifyUrl" v-show="false">
              <el-input v-model="form.mchNotifyUrl"></el-input>
            </el-form-item>
            <el-form-item label="商户号" prop="mchId" v-show="false">
              <el-input v-model="form.mchId"></el-input>
            </el-form-item>
            <el-form-item label="支付类型" prop="mchPayType" v-show="false">
              <el-select v-model="form.mchPayType" placeholder="请选择" style="width: 100%;">
                <el-option
                    v-for="item in this.mchPayTypeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="mchMoney">
              <div class="inputAmount">
                <el-input v-model="form.mchMoney" type="number" :min="1"
                          :step="1" autofocus placeholder="请输入金额" inputmode="numeric"
                ></el-input>
                <el-divider></el-divider>
              </div>
            </el-form-item>

            <el-form-item>
              <el-button type="primary"
                         @click="submitForm"
                         style="width: 100%;border-radius: 0"
                         v-show="createBtn"
              >确认付款
              </el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="success" @click="goPay()" v-show="payBtn" style="width: 100%;border-radius: 0">点我付款
              </el-button>
            </el-form-item>
          </el-form>


        </el-row>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import Request from "@/request";
import CountDown from "@/components/CountDown.vue";

export default {
  name: 'DmfLittleCreateOrder',
  components: {CountDown},
  data() {
    return {
      closeTime: '',
      countDownFlag: false,
      createBtn: true,
      payBtn: false,
      loadingFlag: false,
      h5Url: '',
      returnUrl: '',
      formData: {},
      form: {
        mchId: this.$route.query.mchId,
        mchMoney: '',
        mchNotifyUrl: process.env.VUE_APP_BASE_API + "/test/notify",
        mchPayType: '1015',
        mchApiUrl: process.env.VUE_APP_BASE_API + "/api/order",
      },
      mchPayTypeOptions: [
        {value: "1012", label: "抖音"}
      ],
      rules: {
        mchNotifyUrl: [
          {required: true, message: '请输入回调地址', trigger: 'blur'}
        ],
        mchId: [
          {required: true, message: '请输入商户号', trigger: 'blur'}
        ],
        mchPayType: [
          {required: true, message: '请输入支付类型', trigger: 'blur'}
        ],

      }
    }
  },
  methods: {
    //提交表单
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          //组装数据
          this.formData.mchId = this.form.mchId
          this.formData.mchMoney = this.form.mchMoney
          this.formData.mchNotifyUrl = this.form.mchNotifyUrl
          this.formData.mchPayType = this.form.mchPayType
          this.formData.mchApiUrl = this.form.mchApiUrl
          this.loadingFlag = true

          if(this.formData.mchMoney <1){
            return this.$message.error("下单金额必须大于1元")
          }

          Request.post("/apiPay/create", this.formData).then(res => {
            if (res.code === 0) {
              //付款链接
              this.returnUrl = res.data.payUrl
              // 创建 URL 对象，将 URL 字符串作为参数传入
              let url = new URL(this.returnUrl);
              // 创建 URLSearchParams 对象，将 URL 对象的 search 属性传入
              let searchParams = new URLSearchParams(url.search);
              let orderNo = searchParams.get("no");
              //请求支付参数
              Request.get("/show/qrc?no=" + orderNo).then(res2 => {
                this.loadingFlag = false
                if (res2.code !== 0) {
                  this.$message({message: res2.msg, type: 'error',})
                  return
                }

                this.h5Url = this.$utils.getAlipayDmf( res2.data.payUrl)
                window.location.href = this.h5Url
              }).catch((e) => {
                console.log(e)
                this.loadingFlag = false
                this.$message.error("下单失败2")
              });

            } else {
              this.loadingFlag = false
              this.$message({
                message: "下单失败1",
                type: 'error'
              });
            }
          }).catch(() => {
            this.loadingFlag = false
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    goPay() {
      window.location.href = this.h5Url
    },
    //处理倒计时
    handleCountdownEnd() {
      this.payBtn = false
      this.createBtn = true
      this.countDownFlag = false
      this.$message.error("订单已过期，请重新下单")
    },
  },
}
</script>
<style scoped>
.amountTitle {
  text-align: left;
  color: #606060;
  font-size: 20px;
  font-weight: bold;
  font-family: "Microsoft YaHei", sans-serif;
}

.inputAmount >>> .el-input__inner {
  border: 0;
  box-shadow: 0 0 0 0;
  text-align: right;
  font-size: 30px;
  border-radius: 0;
}
</style>

