<template>
  <div>
    <el-container>
      <el-main>
        <AlipayLogo></AlipayLogo>
        <el-row>
          <Qrcode :content="h5Url" v-show="qrShow"></Qrcode>
          <el-result icon="error" title="订单已超时，请勿超时付款" v-show="timeOut"></el-result>
          <el-result icon="success" title="您已成功付款" v-show="paySuccessFlag"></el-result>
        </el-row>
        <el-row>
          <p style="font-size: 12px;color: red">1.截图保存二维码，支付宝打开扫一扫</p>
          <p style="font-size: 12px;color: red">2.从相册选择当前二维码付款</p>
          <div class="orderInfo">订单金额：<span class="moneyTitle">{{ money }}</span>元</div>
          <div class="orderInfo">
            <CountDown :expirationDateTime="closeTime" :onCountdownEnd="handleCountdownEnd" v-if="countDownFlag"/>
          </div>
          <div class="orderInfo">订单号：
            <el-tag size="small">{{ orderNo }}</el-tag>
          </div>
        </el-row>
        <el-row>
          <AlipayButton :h5Url="h5Url" v-show="payButtonShow"></AlipayButton>
          <el-button @click="dialogFlag = true" style="margin-top: 5px" type="success" size="mini">点我查看支付教程</el-button>
        </el-row>
        <el-row>
          <pay-tips></pay-tips>
        </el-row>
        <el-dialog
            width="85%"
            :visible="dialogFlag"
            :show-close="dialogShowClose"
            center
            top="2vh">
          <img src="../assets/img/fxc3.jpg" alt="" width="100%">
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="dialogFlag = false">我已知晓</el-button>
          </span>
        </el-dialog>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import Request from "@/request";
import AlipayLogo from "@/components/AlipayLogo.vue";
import PayTips from "@/components/PayTips.vue";
import CountDown from "@/components/CountDown.vue"
import Qrcode from "@/components/Qrcode.vue";
import AlipayButton from "@/components/AlipayButton.vue";
import startCheckOrderTimer from "@/service/checkOrder";

export default {
  name: 'AlipayFxc',
  components: {
    PayTips,
    AlipayLogo,
    CountDown,
    Qrcode,
    AlipayButton
  },
  data() {
    return {
      qrShow: false,
      payButtonShow: false,
      orderNo: this.$route.query.no,
      h5Url: '',
      money: 0,
      closeTime: '',
      timeOut: false,
      countDownFlag: true,
      paySuccessFlag: false,
      dialogFlag: true,
      dialogShowClose:false,
    }
  },
  methods: {
    getData() {
      Request.get("/show/qrc?no=" + this.orderNo).then(res => {
        if (res.code !== 0) {
          this.$message({message: res.msg, type: 'error',})
          return
        }
        this.qrShow = true
        //this.payButtonShow = true
        const data = res.data
        this.closeTime = this.$utils.getRealCloseTime(data.closeTime)
        this.h5Url = data.payUrl
        this.money = data.money
      });
    },
    //处理倒计时
    handleCountdownEnd() {
      this.qrShow = false
      this.payButtonShow = false
      this.timeOut = true
      //销毁检查订单任务
      this.stopCheckOrderTimer()
    },
    //处理检查订单状态
    handleCheckOrder(data) {
      if (data.code !== 0) {
        return
      }
      if (data.data.state === "TIMEOUT") {
        //销毁检查订单任务
        this.stopCheckOrderTimer()
      }
      if (data.data.state === "OOK") {
        //显示成功付款提示
        this.paySuccessFlag = true
        //销毁倒计时组件
        this.countDownFlag = false
        //销毁检查订单任务
        this.stopCheckOrderTimer()
        //隐藏付款码
        this.qrShow = false
        this.payButtonShow = false
      }
    }
  },
  mounted() {
    this.getData()
    this.stopCheckOrderTimer = startCheckOrderTimer(this.orderNo, this.handleCheckOrder)
  },
}
</script>
