<template>
  <div>
    <el-button  @click="goPay" type="primary">
      {{buttonName}}
    </el-button>
  </div>
</template>
<script>
export default {
  props:{
    h5Url:{
      type:String,
      require:true
    },
    buttonName:{
      type:String,
      require: true
    }
  },
  methods:{
    goPay(){
      window.location.href = this.h5Url
    }
  }
}
</script>
