<template>
  <div style="font-weight: bold;">
  <el-row type="flex" justify="left">
    <el-col :span="6" class="orderLabel">
    <div>订单金额：</div>
    </el-col>
    <el-col :span="12"></el-col>
    <el-col :span="6">
      <div>{{money}}元</div>
    </el-col>
  </el-row>
  <el-row type="flex" justify="center">
    <el-col :span="6" class="orderLabel">
      <div>订单号：</div>
    </el-col>
    <el-col :span="2"></el-col>
    <el-col :span="16">
      <div>{{orderNo}}</div>
    </el-col>
  </el-row>
  </div>
</template>
<script>
export default {
  props:{
    money:{
      require:true
    },
    orderNo:{
      require:true
    }
  },

}
</script>
<style>
.orderLabel{
  text-align: left;
}
</style>
