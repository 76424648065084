<template>
  <div>
    <el-container>
      <el-main>
        <el-row>
          <el-result icon="error" title="订单已超时，请勿超时付款" v-show="timeOut"></el-result>
          <el-result icon="success" title="您已成功付款" v-show="paySuccessFlag"></el-result>
        </el-row>
        <el-row>
          <p class="payTips" style="color: red">付款步骤，请仔细阅读:</p>
          <p class="payTips">1. <span class="text-red"> 复制链接</span>，手动打开微信</p>
          <p class="payTips">2. 打开任意<span class="text-red">聊天窗口</span>，粘贴发送链接</p>
          <p class="payTips">3. 点击发送的链接，授权手机号，点击 <span class="text-red">我想要</span> 购买商品</p>
          <p class="payTips">4. 付款完成一定要<span class="text-red">确认收货</span>，否则不到账!</p>
        </el-row>
        <el-row>
          <el-tag>{{h5Url}}</el-tag>
          <el-button style="margin-left:5px;" type="primary" size="mini" class="copyUrl" :data-clipboard-text="h5Url" @click="goPay2">复制链接</el-button>
        </el-row>
        <el-row>
          <div class="orderInfo">订单金额：<span class="moneyTitle">{{ money }}</span>元</div>
          <div class="orderInfo">
            <CountDown :expirationDateTime="closeTime" :onCountdownEnd="handleCountdownEnd" v-if="countDownFlag"/>
          </div>
          <div class="orderInfo">订单号：
            <el-tag size="small">{{ orderNo }}</el-tag>
          </div>
        </el-row>
        <el-row>
          <el-button type="primary" class="copy" :data-clipboard-text="h5Url" v-show="payButtonShow" @click="goPay()">
            打开微信
          </el-button>
        </el-row>
        <el-row>
          <el-button @click="dialogFlag = true" style="margin-top: 5px" type="success" size="mini">点我查看支付教程
          </el-button>
        </el-row>

        <el-dialog
            title="支付步骤(重要!!!)"
            :visible.sync="dialogFlag"
            :show-close="false"
            width="90%"
            center
            top="2vh">
          <img src="../assets/img/wxxy.jpg" alt="" width="100%">
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="dialogFlag=false">我已了解</el-button>
          </span>
        </el-dialog>


      </el-main>
    </el-container>
  </div>
</template>
<script>
import Request from "@/request";
import CountDown from "@/components/CountDown.vue"
import startCheckOrderTimer from "@/service/checkOrder";
import ClipboardJS from "clipboard";

export default {
  name: 'AlipayUid',
  components: {
    CountDown,
  },
  data() {
    return {
      qrShow: false,
      payButtonShow: false,
      orderNo: this.$route.query.no,
      h5Url: '',
      money: 0,
      closeTime: '',
      timeOut: false,
      countDownFlag: true,
      paySuccessFlag: false,
      dialogFlag: true,
    }
  },
  methods: {
    getData() {
      //订单号
      Request.get("/show/qrc?no=" + this.orderNo).then(res => {
        if (res.code !== 0) {
          this.$message({message: res.msg, type: 'error',})
          return
        }
        this.qrShow = true
        //this.payButtonShow = true
        const data = res.data
        this.closeTime = this.$utils.getRealCloseTime(data.closeTime)
        this.h5Url = data.wxUrl
        this.money = data.money
      });
    },
    //处理倒计时
    handleCountdownEnd() {
      this.qrShow = false
      this.payButtonShow = false
      this.timeOut = true
      this.dialogFlag = false
      //销毁检查订单任务
      this.stopCheckOrderTimer()
    },
    //处理检查订单状态
    handleCheckOrder(data) {
      if (data.code !== 0) {
        return
      }
      if (data.data.state === "TIMEOUT") {
        //销毁检查订单任务
        this.stopCheckOrderTimer()
      }
      if (data.data.state === "OOK") {
        //显示成功付款提示
        this.paySuccessFlag = true
        //销毁倒计时组件
        this.countDownFlag = false
        //销毁检查订单任务
        this.stopCheckOrderTimer()
        //隐藏付款码
        this.qrShow = false
        this.payButtonShow = false
      }
    },

    //跳转支付
    goPay() {
      //window.location.href = this.h5Url
      const clipboard = new ClipboardJS('.copy');
      clipboard.on('success', (e) => {
        this.$message({
          message: "已复制小程序链接，请点击同意跳转到微信!", type: "success", duration: 2000, onClose: () => {
            window.location.href = "weixin://"
          }
        })
        e.clearSelection();
        clipboard.destroy(); // 取消事件监听
      });
      clipboard.on('error', () => {
        this.$message.error("复制链接失败，请联系平台")
        clipboard.destroy(); // 取消事件监听
      });
    },
    goPay2() {
      const clipboard = new ClipboardJS('.copyUrl');
      clipboard.on('success', (e) => {
        this.$message({
          message: "已复制小程序链接，请手动打开微信粘贴", type: "success", duration: 2000
        })
        e.clearSelection();
        clipboard.destroy(); // 取消事件监听
      });
      clipboard.on('error', () => {
        this.$message.error("复制链接失败，请联系平台")
        clipboard.destroy(); // 取消事件监听
      });
    }
  },
  mounted() {
    this.getData()
    this.stopCheckOrderTimer = startCheckOrderTimer(this.orderNo, this.handleCheckOrder)
  },
}
</script>
<style>
.payTips {
  font-size: 15px;
  font-weight: bold;
  text-align: left;

}
</style>
