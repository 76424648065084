import axios from 'axios'
import { Message} from 'element-ui'
const baseURL = process.env.VUE_APP_BASE_API;

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

/**
 * axios 响应拦截器
 * @param {object} response 从服务端响应的数据对象或者error对象
 * @return {object} 响应成功或失败时返回的响应对象或者promise error对象
 **/
axios.interceptors.response.use(response => {
    //如果服务器有响应，状态码不是200
    if(response.status !== 200){
        Message({
            message: '服务器响应失败！--'+response.status,
            type: 'error'
        })
        return
    }
    if(response.data === undefined){
        Message({
            message: "订单号查询失败",
            type: 'error'
        })
        return
    }
    /*if(response.data.code !== 0){
        Message({
            message: response.data.msg,
            type: 'error'
        })
        return
    }*/
    return response.data;
}, error => {
    if (error.response === undefined) {
        Message({
            message: '请求服务器失败，请刷新当前页面或者切换网络',
            type: 'error'
        })
        return
    }
    return Promise.reject(error.response)
})

/**
 * 发送get请求
 * @param url
 * @param params
 * @returns {Promise<unknown>}
 */
const get = function (url, params = {}) {
    return axios({
        baseURL,
        url,
        method: "get",
        params,
        timeout: 30000,
        traditional: true,
        // paramsSerializer: function (params) {
        //     return qs.stringify(params, {arrayFormat: 'repeat'})
        // }
    });
}

/**
 * 发送post请求
 */
const post = function (url, data = {}, params = {}, method = 'post') {
    return axios({
        baseURL,
        url, method, params, data, timeout: 30000,
    })
}


// 请求方式的配置
export default {
    post, get
}
