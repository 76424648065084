<template>
  <div>
    请在
    <span style="color:#ff6d00">{{ minutes }}</span>
    分
    <span style="color:#ff6d00">{{ seconds }}</span>
    秒 内完成支付
    <div style="font-weight: bold;color: red;font-size: 16px;">超时支付无法到账</div>
  </div>
</template>
<script>
import moment from 'moment-timezone';

export default {
  props: {
    expirationDateTime: {
      type: String,
      required: true
    },
    onCountdownEnd: {
      type: Function,
      default: () => {
      }
    }
  },
  data() {
    return {
      expirationTimestamp: this.calculateExpirationTimestamp(),
      remainingTime: this.calculateRemainingTime(),
      timer: null // 添加 timer 属性
    };
  },
  computed: {
    minutes() {
      return Math.floor(this.remainingTime / 60);
    },
    seconds() {
      return this.remainingTime % 60;
    }
  },
  watch: {
    expirationDateTime(newValue) {
      // 当 expirationDateTime 发生变化时重新计算剩余时间
      this.expirationTimestamp = this.calculateExpirationTimestamp();
      this.remainingTime = this.calculateRemainingTime();
      // 重新开始倒计时
      this.updateRemainingTime();
    }
  },
  methods: {
    calculateExpirationTimestamp() {
      // 创建 Moment 对象，并将日期时间字符串解析为日期时间，然后将时区设置为东八区
      const expirationMoment = moment.tz(this.expirationDateTime, 'Asia/Shanghai');

      // 获取时间戳（单位：毫秒）
      const expirationTimestamp = expirationMoment.valueOf();

      // 将时间戳转换为秒，并返回
      return Math.floor(expirationTimestamp / 1000);
    },
    calculateRemainingTime() {
      const currentTime = Math.floor(Date.now() / 1000);
      return Math.max(0, this.expirationTimestamp - currentTime);
    },
    updateRemainingTime() {
      this.remainingTime = this.calculateRemainingTime();
      if (this.remainingTime > 0) {
        window.requestAnimationFrame(() => this.updateRemainingTime());
      } else {
        this.onCountdownEnd();
      }
    }
  },
  mounted() {
    //this.updateRemainingTime();
  },
  beforeDestroy() {
    // 在组件销毁前清理 requestAnimationFrame
    cancelAnimationFrame(this.timer);
  }
};
</script>

<style scoped>
/* 样式可以根据需要自行添加 */
</style>
