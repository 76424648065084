import Request from "@/request";

/**
 * 检查订单状态
 * @param no
 * @param onDataReceived
 * @returns {(function(): void)|*}
 */
export default function startCheckOrderTimer(no,onDataReceived) {
    const timer = setInterval(async () => {
        Request.get("/show/order?no=" + no).then(res => {
            onDataReceived(res)
        }).catch(e=>{
            onDataReceived(e)
        });

    }, 10000);

    // 返回一个清理函数，用于在页面销毁时停止定时任务
    return function stopCheckOrderTimer() {
        clearInterval(timer);
    };
}
