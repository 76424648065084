<template>
  <div style="font-weight: bold;">
  <el-row type="flex" justify="left">
    <el-col :span="6" class="orderLabel">
    <div>订单金额：</div>
    </el-col>
    <el-col :span="12"></el-col>
    <el-col :span="6">
      <div>{{money}}元</div>
    </el-col>
  </el-row>
  <el-row type="flex" justify="center">
    <el-col :span="6" class="orderLabel">
      <div>订单号：</div>
    </el-col>
    <el-col :span="2"></el-col>
    <el-col :span="16">
      <div>{{orderNo}}</div>
    </el-col>
  </el-row>
    <el-button type="primary" size="mini" class="copyOrderNo" :data-clipboard-text="orderNo" @click="handleCopy">复制订单号</el-button>
  </div>
</template>
<script>
import ClipboardJS from "clipboard";

export default {
  props:{
    money:{
      require:true
    },
    orderNo:{
      require:true
    }
  },
  methods:{
    handleCopy() {
      const clipboard = new ClipboardJS('.copyOrderNo');
      clipboard.on('success', (e) => {
        this.$message.success("成功复制订单号:" + e.text )
        e.clearSelection();
        clipboard.destroy(); // 取消事件监听
      });
      clipboard.on('error', () => {
        this.$message.error("复制失败，请手动复制")
        clipboard.destroy(); // 取消事件监听
      });
    }
  }

}
</script>
<style>
.orderLabel{
  text-align: left;
}
</style>
