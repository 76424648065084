<template>
  <div>
    <el-container>
      <el-main>
        <div>
          <div class="tips">付款步骤，请仔细阅读:</div>
          <div class="tips">1.<span class="text-red">截屏当前付款码</span></div>
          <div class="tips">2.<span class="text-red">打开淘宝扫一扫选择相册截屏的码</span></div>
          <div class="tips">3.下单把复制的账号粘贴在 <span class="text-red">游戏账号</span></div>
          <div class="tips"><span class="text-red">区/服可随意填写</span></div>
          <div class="tips">4.购买商品完成后，一定要在<span class="text-red">淘宝我的订单确认收货</span></div>
          <div class="tips">5.重要！！！支付成功以后一定要<span class="text-red">确认收货</span>秒上分 </div>
          <div class="tips"><span class="text-red">系统已复制好账号信息,一定要粘贴下面的账号填写,否则不退不补</span></div>
        </div>
        <el-row>
          <Qrcode :content="h5Url" v-show="qrShow"></Qrcode>
          <el-result icon="error" title="订单已超时，请勿超时付款" v-show="timeOut"></el-result>
          <el-result icon="success" title="您已成功付款" v-show="paySuccessFlag"></el-result>
        </el-row>
        <el-row>
          <div class="orderInfo">订单金额：<span class="moneyTitle">{{ money }}</span>元</div>
          <div class="orderInfo">
            <CountDown :expirationDateTime="closeTime" :onCountdownEnd="handleCountdownEnd" v-if="countDownFlag"/>
          </div>
          <div class="orderInfo">订单号：
            <el-tag size="small">{{ orderNo }}</el-tag>
          </div>
          <div>
            账号:
            <el-tag size="mini">{{ accountName }}</el-tag>
            <br/>
            <el-button style="margin-left:5px;margin-top: 5px;" size="medium" class="copy"
                       :data-clipboard-text="accountName"
                       type="primary" v-show="payButtonShow" @click="goPay()">复制账号
            </el-button>
            <img src="../assets/img/taobaoGame2.jpg" style="width: 100%;">

          </div>
          <div>
            <!--            <img src="../assets/img/taobaoGame.jpg" style="width: 100%;height: 250px;">-->
          </div>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import Request from "@/request";
import CountDown from "@/components/CountDown.vue"
import startCheckOrderTimer from "@/service/checkOrder";
import ClipboardJS from "clipboard";
import Qrcode from "@/components/Qrcode.vue";

export default {
  name: 'TaobaoGame',
  components: {
    Qrcode,
    CountDown,
  },
  data() {
    return {
      qrShow: false,
      payButtonShow: false,
      orderNo: this.$route.query.no,
      h5Url: '',
      money: 0,
      closeTime: '',
      timeOut: false,
      countDownFlag: true,
      paySuccessFlag: false,
      dialogFlag: true,
      showCloseFlag: true,
      accountName: '',
    }
  },
  methods: {
    getData() {
      //订单号
      Request.get("/show/qrc?no=" + this.orderNo).then(res => {
        if (res.code !== 0) {
          this.$message({message: res.msg, type: 'error',})
          return
        }
        this.qrShow = true
        this.payButtonShow = true
        const data = res.data
        this.closeTime = this.$utils.getRealCloseTime(data.closeTime)
        this.h5Url = this.$utils.getTaobaoZhifu(data.goodsId)
        console.log(this.h5Url)
        this.money = data.money
        this.accountName = data.accountName
      });
    },
    //处理倒计时
    handleCountdownEnd() {
      this.qrShow = false
      this.payButtonShow = false
      this.timeOut = true
      this.dialogFlag = false
      //销毁检查订单任务
      this.stopCheckOrderTimer()
    },
    //处理检查订单状态
    handleCheckOrder(data) {
      if (data.code !== 0) {
        return
      }
      if (data.data.state === "TIMEOUT") {
        //销毁检查订单任务
        this.stopCheckOrderTimer()
      }
      if (data.data.state === "OOK") {
        //显示成功付款提示
        this.paySuccessFlag = true
        //销毁倒计时组件
        this.countDownFlag = false
        //销毁检查订单任务
        this.stopCheckOrderTimer()
        //隐藏付款码
        this.qrShow = false
        this.payButtonShow = false
      }
    },
    //跳转支付
    goPay() {
      const clipboard = new ClipboardJS('.copy');
      clipboard.on('success', (e) => {
        this.$message({
          message: "已复制充值账号信息，请粘贴在 游戏账号 输入框", type: "success", duration: 3000,onClose:()=>{
            //window.location.href = this.h5Url
          }
        })
        e.clearSelection();
        clipboard.destroy(); // 取消事件监听
      });
      clipboard.on('error', () => {
        this.$message.error("复制充值账号信息失败，请手动填写!")
        clipboard.destroy(); // 取消事件监听
      });
    }
  },
  mounted() {
    this.getData()
    this.stopCheckOrderTimer = startCheckOrderTimer(this.orderNo, this.handleCheckOrder)
  },
}
</script>
<style>
.tips {
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 3px;
}
</style>
