<template>
  <div style="display: flex;
  justify-content: center;
  align-items: center;">
    <div class="qrcode" ref="qrCodeUrl" ></div>
  </div>
</template>
<script >
import QRCode from 'qrcodejs2'
export default {
  props:{
    content:{type:String}
  },
  methods:{
    //创建二维码函数
    creatQrCode(content = this.content) {
      new QRCode(this.$refs.qrCodeUrl, {
        text: content, // 需要转换为二维码的内容
        width: 200,
        height: 200,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.L,
      })
    },
  },
  mounted() {
    //this.creatQrCode();
  },
  watch: {
    content(newData) {
      this.creatQrCode(newData);
    },
  },

}
</script>